import { useState, useEffect } from 'react'

export default (withLazy) => {
  const [isImageRendered, setIsImageRendered] = useState(!withLazy)

  useEffect(() => {
    if(!withLazy) return
    setTimeout(() => {
      setIsImageRendered(true)
    })
  }, [withLazy])

  return isImageRendered
}