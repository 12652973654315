/* @flow */
import React, { useState, useEffect, memo } from 'react'
import LazyLoad from 'react-lazy-load'
import { _debounce } from '@website/common/utils'
import { getImgSrc } from './utils'
import ImgWithCropParams from './ImgWithCropParams'
import { GlobalStyles, FailedImageContainer } from './styled'
import {
  BLANK_IMAGE_URL,
  IMAGE_UPLOAD_FAIL_ICON
} from '@website/conf/asset-urls'
import useImageDelayedRender from './useImageDelayedRender'

export const ImageElem = props => {
  const { sizes, onError, onLoad, withLazy = true } = props

  const [loaded, setLoadedStatus] = useState(false)
  const [imgSrc, setImgSrc] = useState(getImgSrc(sizes))
  const isImageRendered = useImageDelayedRender(withLazy) // Fixing lazy-loading issue related to scroll

  const _setImgSrc = () => {
    setImgSrc(getImgSrc(sizes))
  }

  const handleOnLoad = e => {
    onLoad && onLoad(e)
    setLoadedStatus(true)
  }

  const handleError = e => {
    onError && onError(e)
    setImgSrc(BLANK_IMAGE_URL)
    setLoadedStatus(true)
  }

  useEffect(() => {
    const debouncedImgSourceSetter = _debounce(_setImgSrc, 200)
    window.addEventListener('resize', debouncedImgSourceSetter)
    return () => {
      window.removeEventListener('resize', debouncedImgSourceSetter)
    }
  }, Object.values(sizes))

  useEffect(() => {
    setLoadedStatus(false)
    _setImgSrc()
  }, Object.values(sizes))

  useEffect(_setImgSrc, [sizes])

  return (
    <>
      <GlobalStyles />
      {withLazy ? (
        isImageRendered ? (
          <LazyLoad offset={500}>
            <ImgWithCropParams
              {...props}
              src={imgSrc}
              loaded={loaded}
              onLoad={handleOnLoad}
              onError={handleError}
            />
          </LazyLoad>
        ) : null
      ) : (
        <ImgWithCropParams
          {...props}
          src={imgSrc}
          loaded={true}
          onLoad={handleOnLoad}
          onError={handleError}
        />
      )}
    </>
  )
}

const ImageWithInvalidState = props => {
  const isInvalidImage = !props.sizes || !Object.keys(props.sizes).length
  const { withLazy = true, onLoad } = props

  const renderFiledImage = () => (
    <FailedImageContainer className="lazy-figure failed-image-container">
      <img src={IMAGE_UPLOAD_FAIL_ICON} alt="Failed" onLoad={onLoad} />
    </FailedImageContainer>
  )

  return isInvalidImage ? (
    withLazy ? (
      <LazyLoad offset={500}>{renderFiledImage()}</LazyLoad>
    ) : (
      renderFiledImage()
    )
  ) : (
    <ImageElem {...props} />
  )
}

export default memo(ImageWithInvalidState)
